






















import { CarDealer } from '@/api/interfaces/cardealer'
import { QueryParams } from '@/store/api-plateform-utils'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'SelectSearchCarDealers',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: [Object as () => CarDealer, Array as () => CarDealer[]],
      required: false,
      default: () => null,
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideDetails: {
      type: String,
      required: false,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (!this.multiple && this.value && (this.value as CarDealer).code) {
          this.carDealerList = [this.value as CarDealer]
        }
      },
    },
  },
  data () {
    return {
      carDealerList: [] as CarDealer[],
      searching: false,
    }
  },
  methods: {
    ...mapActions('carDealerList', {
      load: 'load',
    }),
    itemText (item: CarDealer) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      if (item?.carDealerRegion) {
        return `${item.code} | ${item.name} | ${item.carDealerRegion}`
      }
      return `${item.code} | ${item.name}`
    },
    async loadCarDealers (searchText: string) {
      // to not reload the first 30 company at each add of a new company when multiple is active
      if ((!searchText || searchText.length < 1) && this.multiple) {
        return
      }
      const request: QueryParams = {
        page: 1,
        itemsPerPage: 30,
      }
      if (searchText) {
        request.searchCarDealer = searchText
      }
      this.searching = true
      this.carDealerList = await this.load(request)
      this.searching = false
    },
    onInput (value: string | CarDealer[]) {
      if (this.multiple) {
        this.$emit('input', value)
      } else {
        const carDealer = (this.carDealerList as Array<CarDealer>).find(
          x => x['@id'] === value,
        )
        this.$emit('input', carDealer)
      }
    },
  },
})
